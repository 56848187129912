<template>
  <!--<el-dialog-->
  <!--v-dialogDrag-->
  <!--:title="!dataForm.id ? '配方详情' : '配方详情'"-->
  <!--:close-on-click-modal="false"-->
  <!--:visible.sync="visible"-->
  <!--width="750px"-->
  <!--@close="close()"-->
  <!--&gt;-->
  <sides-lip ref="SidesLip" @click="close()">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        size="small"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmit()"
        label-width="120px"
        v-loading="dataLoading"
        style="width: 100%"
        element-loading-text
        element-loading-spinner="el-icon-loading"
      >
        <el-form-item label="产品号" prop="productNo">
          <el-input
            v-model="dataForm.productNo"
            placeholder="产品号"
            size="small"
            @input="getProductInfo"
            disabled="disabled"
          ></el-input>
        </el-form-item>
        <!--<div class="productDirs" v-for="x in productInfo">-->
        <div
          v-show="productInfo !== undefined && productInfo.length > 0"
          class="productDirs"
          v-for="(x, index) in productInfo"
          :key="index"
        >
          <div class="left">
            <p>
              产品名称：
              <span class="fontNormal">{{ x.productName }}</span>
            </p>
            <p>
              产品号：
              <span class="fontNormal">{{ x.productNo }}</span>
            </p>
            <p>
              L*a*b*：
              <span class="fontNormal"
                >{{ x.aAve }} {{ x.bAve }} {{ x.lAve }}</span
              >
            </p>
          </div>
          <div class="right">
            <el-image
              v-if="x.picUrl"
              style="width: 180px; height: 100px"
              :src="x.picUrl"
              fit="contain"
            ></el-image>
            <div
              v-else
              :style="{
                backgroundColor: x.hex,
                height: '100px',
                width: '100px',
              }"
            ></div>
          </div>
        </div>
        <div
          v-show="
            dataForm.recipeMaterial !== undefined &&
            dataForm.recipeMaterial.length > 0
          "
          class="materialInfo"
          style="height: 250px; overflow: auto"
        >
          <el-table
            :data="dataForm.recipeMaterial"
            border
            v-loading="dataListLoading"
            style="width: 100%"
          >
            <el-table-column
              prop="materialNo"
              header-align="center"
              align="center"
              label="物料编号"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  v-if="
                    scope.row.componentId != null || scope.row.productId != null
                  "
                  @click="
                    skipMaterial(scope.row.productId, scope.row.componentId)
                  "
                  >{{ scope.row.productNo }}{{ scope.row.sampleNo }}</el-link
                >
                <label v-if="scope.row.materialNo != null">{{
                  scope.row.materialNo
                }}</label>
                <label v-if="scope.row.materialNo == null">{{
                  scope.row.productNo
                }}</label>
              </template>
            </el-table-column>
            <el-table-column
              prop="materialName"
              header-align="center"
              align="center"
              label="原料名称"
            >
            </el-table-column>
            <el-table-column
              prop="value"
              header-align="center"
              align="center"
              label="计量值"
            >
            </el-table-column>
            <el-table-column
              prop="materialUnits"
              header-align="center"
              align="center"
              label="单位"
            >
            </el-table-column>
            <el-table-column
              prop="rate"
              header-align="center"
              align="center"
              label="计量百分比"
            >
            </el-table-column>
          </el-table>
        </div>
        <!-- <div v-if="dataCipeSize != 0 &&  dataForm.recipeMaterial!==undefined&&dataForm.recipeMaterial.length>0" class="materialInfo"  style="height: 250px;overflow:auto">
                    <p>邦定工艺</p>
                    <el-table :data="dataCipeList"
                        border style="width: 100%;">
                        <el-table-column
                                prop="addStart"
                                header-align="center"
                                align="center"
                                label="add start开始">
                        </el-table-column>
                        <el-table-column
                                prop="addBonding"
                                header-align="center"
                                align="center"
                                label="add bonding过程">
                        </el-table-column>
                        <el-table-column
                                prop="rpm"
                                header-align="center"
                                align="center"
                                label="Rpm 速度">
                        </el-table-column>
                        <el-table-column
                                prop="temp"
                                header-align="center"
                                align="center"
                                label="Temp 温度">
                        </el-table-column>
                        <el-table-column
                                prop="time"
                                header-align="center"
                                align="center"
                                label="Time 时间">
                        </el-table-column>
                        <el-table-column
                                prop="cocktail"
                                header-align="center"
                                align="center"
                                label="cocktail 干混">
                        </el-table-column>
                    </el-table>
                </div> -->
        <div v-if="dataCipeSize != 0">
          <p>邦定工艺</p>
          <el-table
            :data="dataCipeList"
            border
            :span-method="objectSpanMethod"
            style="width: 100%"
          >
            <el-table-column
              prop="addStart"
              header-align="center"
              align="center"
              label="工序"
            >
            </el-table-column>
            <el-table-column
              prop="data"
              header-align="center"
              align="center"
              label="数据"
            >
            </el-table-column>
            <el-table-column
              prop="weight"
              header-align="center"
              align="center"
              label="重量"
            >
            </el-table-column>
          </el-table>
        </div>
        <br />
        <!-- <el-form-item label="固化条件" prop="solidifyName">
                    <el-input v-model="dataForm.solidifyName" placeholder="固化条件" size="small" disabled="disabled"></el-input>
                </el-form-item> -->
        <el-form-item label="固化温度" prop="temperature">
          <el-input
            v-model="dataForm.temperature"
            placeholder="固化温度"
            size="small"
            disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="固化时间" prop="solTime">
          <el-input
            v-model="dataForm.solTime"
            placeholder="固化时间（分钟）"
            size="small"
            disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="固化条件说明" prop="solidifyDesc">
          <el-input
            type="textarea"
            :rows="5"
            v-model="dataForm.solidifyDesc"
            size="small"
            disabled="disabled"
          ></el-input>
        </el-form-item>
      </el-form>
      <!--<span slot="footer" class="dialog-footer">-->
      <!--<el-button @click="close()" size="small">关闭</el-button>-->
      <!--<el-button type="primary" size="small" @click="SampleHandle(dataForm.id)">生成打样</el-button>-->
      <!--</span>-->
      <!--<sample v-if="sampleVisible" ref="sample"></sample>-->
      <!--物料跳转-->
      <material-recipe ref="materialRecipe"></material-recipe>
    </div>

    <div slot="soltFooter">
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">返回</el-button>
        <!--<el-button type="primary" size="small" @click="SampleHandle(dataForm.id)">生成打样</el-button>-->
      </span>
    </div>
    <!--</el-dialog>-->
  </sides-lip>
</template>

<script>
import {
  update,
  add,
  recipeInfo,
  queryMateriaInfo,
  querySolidify,
  queryProduct,
  getSolidifyInFo,
} from "@/api/modules/recipe";
import { selectRecipe, listAllSCipe } from "@/api/modules/recipe";
/*import sample from './sample'*/
import MaterialRecipe from "./materialRecipe";
import SidesLip from "@/components/SidesLip";
export default {
  data() {
    return {
      materialRecipeVisible: false,
      visible: false,
      dataLoading: false,
      dataForm: {
        id: 0,
        productNo: "",
        recipeNo: "",
        productId: "",
        recipeId: "",
        recipeMaterial: [],
        solidifyId: "",
        solTime: "",
        temperature: "",
        solidifyDesc: "",
        selMaterial: {},
      },
      dataRule: {},
      dataListLoading: false,
      sampleVisible: false,
      productInfo: [],
      materialList: [],
      dataCipeList: [],
      dataCipeSize: 0,
      cellList: [], // 单元格数组
      count: null, // 计数
      solidifyList: [],
    };
  },
  components: {
    // sample,
    MaterialRecipe,
    SidesLip,
  },
  methods: {
    //点击超链接文字，触发跳转页面
    skipMaterial(productId, componentId) {
      this.materialRecipeVisible = true;
      this.$nextTick(() => {
        this.$refs.materialRecipe.init(productId, componentId);
      });
    },
    solidifyDD(id) {
      var params = {
        id: id,
      };
      //固化
      getSolidifyInFo(params).then(({ data }) => {
        if (data.code == 0) {
          this.dataForm.solidifyName = data.solidifyInFo.solidifyName;
          this.dataForm.temperature = data.solidifyInFo.temperature;
          this.dataForm.solTime = data.solidifyInFo.solTime;
          this.dataForm.solidifyDesc = data.solidifyInFo.description;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    close() {
      this.productInfo = [];
      this.materialList = [];
      this.dataForm.selMaterial = {};
      this.visible = false;
      this.$refs.SidesLip.sideClose();
    },
    init(id) {
      this.productInfo = [];
      this.dataForm.recipeMaterial = [];
      this.dataForm.id = id || 0;
      this.visible = true;
      (this.dataCipeList = []),
        (this.dataCipeSize = 0),
        (this.cellList = []), // 单元格数组
        (this.count = null), // 计数
        this.$nextTick(() => {
          this.$refs.SidesLip.init(
            this.dataForm.id ? "配方详情" : "配方详情",
            "800px",
            "block"
          );
          //this.$refs["dataForm"].resetFields();
          if (this.dataForm.id) {
            selectRecipe({ id: this.dataForm.id }).then(({ data }) => {
              //获去配方详情
              if (data && data.code === 0) {
                this.dataForm.productNo = data.recipeList.productNo;
                this.dataForm.productName = data.recipeList.productName;
                this.dataForm.solTime = data.recipeList.solTime;
                this.dataForm.temperature = data.recipeList.temperature;
                this.dataForm.solidifyDesc = data.recipeList.solidifyDesc;
                this.dataForm.productId = data.recipeList.productId;
                this.dataForm.recipeId = data.recipeList.id;
                if (data.recipeList.recipeMaterial) {
                  this.dataForm.recipeMaterial = data.recipeList.recipeMaterial;
                }
                var obj = {
                  lAve: data.recipeList.lave,
                  aAve: data.recipeList.aave,
                  bAve: data.recipeList.bave,
                  picUrl: data.recipeList.picUrl,
                  hex: data.recipeList.hex,
                  productNo: data.recipeList.productNo,
                  productName: data.recipeList.productName,
                };
                this.productInfo.push(obj);
                this.dataForm.lAve = data.recipeList.lave;
                this.dataForm.aAve = data.recipeList.aave;
                this.dataForm.bAve = data.recipeList.bave;
                this.dataForm.hex = data.recipeList.hex;
                this.dataForm.picUrl = data.recipeList.picUrl;
              }
            });
            listAllSCipe({ id: this.dataForm.id }).then(({ data }) => {
              if (data && data.code === 0) {
                this.dataCipeList = data.specialrecipeDatas;
                this.dataCipeSize = data.totalCount;
                this.computeCell(this.dataCipeList);
              } else {
                this.dataCipeList = [];
                this.dataCipeSize = 0;
              }
            });
          }
        });
    },
    getProductInfo(value) {
      queryProduct({ productNo: value }).then(({ data }) => {
        if (data.code == 0 && data.productList.length > 0) {
          this.dataForm.productId = data.productList[0].productId;
          this.productInfo = data.productList;
        }
      });
    },
    addMaterial() {
      if (JSON.stringify(this.dataForm.selMaterial) == "{}") {
        this.$message.error("请先选择物料！");
        return;
      }
      let obj = {
        materialName: this.dataForm.selMaterial.materialName,
        value: 0,
        materialUnits: this.dataForm.selMaterial.materialUnits,
      };
      this.dataForm.recipeMaterial.push(obj);
    },
    del(index) {
      this.dataForm.recipeMaterial.splice(index, 1);
    },
    computeCell(tableBody) {
      // 循环遍历表体数据
      for (let i = 0; i < tableBody.length; i++) {
        if (i == 0) {
          // 先设置第一项
          this.cellList.push(1); // 初为1，若下一项和此项相同，就往cellList数组中追加0
          this.count = 0; // 初始计数为0
          console.log("索引", 0, this.count);
        } else {
          // 判断当前项与上项的设备类别是否相同，因为是合并这一列的单元格
          if (tableBody[i].addStart == tableBody[i - 1].addStart) {
            // 如果相等
            this.cellList[this.count] += 1; // 增加计数
            this.cellList.push(0); // 相等就往cellList数组中追加0
            console.log("索引", i, this.count);
          } else {
            this.cellList.push(1); // 不等就往cellList数组中追加1
            this.count = i; // 将索引赋值为计数
            console.log("索引", i, this.count);
          }
        }
      }
    },
    // 第2步，将计算好的结果返回给el-table，这样的话表格就会根据这个结果做对应合并列渲染
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 给第二列做单元格合并。0是第一列，1是第二列。
      if (columnIndex === 0) {
        console.log("单元格数组，若下一项为0，则代表合并上一项", this.cellList);
        const rowCell = this.cellList[rowIndex];
        if (rowCell > 0) {
          const colCell = 1;
          console.log(
            `动态竖向合并单元格, 第${colCell}列，竖向合并${rowCell}个单元格 `
          );
          return {
            rowspan: rowCell,
            colspan: colCell,
          };
        } else {
          // 清除原有的单元格，必须要加，否则就会出现单元格会被横着挤到后面了！！！
          // 本例中数据是写死的不会出现，数据若是动态后端获取的，就会出现了！！！
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
  },
};
</script>
<style lang="scss">
.materialInfo {
  .el-input {
    input {
      width: 120px;
    }
  }
}
.el-date-editor.el-input {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.productDirs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  padding-bottom: 10px;
  //  border-bottom: 1px solid #d8d8d8;
  .left {
    margin-left: 100px;
  }
  .right {
    margin-right: 50px;
  }
}
.materialInfo {
  ul {
    margin: 0;
    padding: 10px 20px 0;
    border-bottom: 1px solid #d8d8d8;
  }
  ul li {
    list-style: none;
    margin-left: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .materialName {
      width: 200px;
    }
  }
}
.ml30 {
  margin-left: 30px;
}
.mr50 {
  margin-right: 50px;
}
.mr30 {
  margin-right: 30px;
}
.mr20 {
  margin-right: 20px;
}
.mr10 {
  margin-right: 10px;
}
.mt15 {
  margin-top: 15px;
}
.fontNormal {
  font-weight: normal;
}
</style>
