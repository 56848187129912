<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="productName">
        <el-input
          v-model="dataForm.productName"
          placeholder="产品名称"
          size="small"
          clearable
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item prop="productNo">
        <el-input
          v-model="dataForm.productNo"
          placeholder="产品号"
          size="small"
          clearable
          maxlength="30"
        ></el-input>
      </el-form-item>
      <!--<el-form-item prop="daterange">-->
      <!--<el-date-picker type="daterange" v-model="dataForm.daterange" range-separator="~" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss"-->
      <!--:editable="false" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>-->
      <!--</el-form-item>-->
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <el-button
          v-if="isAuth('recipe:recipe:save')"
          type="primary"
          @click="addOrUpdateHandle()"
          size="small"
          >新增</el-button
        >
        <el-button
          v-if="isAuth('recipe:recipe:import')"
          type="success"
          @click="uploadHandle()"
          :disabled="dataList.length < 0"
          >导入</el-button
        >
        <el-button
          v-if="isAuth('recipe:recipe:import')"
          type="primary"
          @click="excelTemplateHandle()"
          size="small"
          >Excel模板</el-button
        >
        <el-button
          v-if="isAuth('material:materialcategory:save')"
          type="primary"
          @click="exportExcelHandle()"
          size="small"
          >导出
        </el-button>
        <!--<el-button v-if="isAuth('recipe:recipe:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" size="small">批量删除</el-button>-->

      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="productNo"
        header-align="center"
        align="center"
        label="产品号"
      >
      </el-table-column>
      <el-table-column
        prop="productName"
        header-align="center"
        align="center"
        label="产品名称"
      >
      </el-table-column>
      <el-table-column
        prop="lab"
        header-align="center"
        align="center"
        label="45度平均LAB值"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.lab"
            >L:{{ scope.row.lab.lAve }} A:{{ scope.row.lab.aAve }} B:{{
              scope.row.lab.bAve
            }}</span
          >
        </template>
      </el-table-column>
      <!--<el-table-column
        prop="recipeMaterial"
        header-align="center"
        align="center"
        label="配方物料">
         <template slot-scope="scope">
           <span  v-for="x in scope.row.recipeMaterial">{{x.materialName}}-&#45;&#45;{{x.value}}|{{x.units}}&#45;&#45;</span>
         </template>
      </el-table-column>-->
      <!-- <el-table-column
        prop="solidifyName"
        header-align="center"
        align="center"
        label="固化条件">
      </el-table-column> -->
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('recipe:recipe:info')"
            type="text"
            size="small"
            @click="addRecipeHandle(scope.row.productId)"
            >查看</el-button
          >
          <el-button
            v-if="isAuth('recipe:recipe:update')"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id, scope.row.productId)"
            >编辑</el-button
          >
          <!-- <el-button
            v-if="isAuth('recipe:recipe:delete')"
            type="text"
            size="small"
            style="color: red"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 编辑 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <upload
      v-if="uploadVisible"
      ref="upload"
      @refreshDataList="getDataList"
    ></upload>
    <materialRecipe
      v-if="materialRecipeVisible"
      ref="materialRecipe"
      @refreshDataList="getDataList"
    ></materialRecipe>
  </div>
</template>

<script>
import {
  list,
  del,
  recipeImportExcel,
  reMaImportExcel,
  isRecipeOrder,
} from "@/api/modules/recipe";
import AddOrUpdate from "./add-or-update";
import { getToken } from "@/utils/auth";
import Upload from "./recipe-upload";
import MaterialRecipe from "./materialRecipe";
import { axiosDownFile } from "@/utils";
export default {
  data() {
    return {
      dataForm: {
        productName: "",
        productNo: "",
        lAve: "",
        aAve: "",
        bAve: "",
        daterange: [],
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      uploadVisible: false,
      addOrUpdateVisible: false,
      materialRecipeVisible: false,
    };
  },
  components: {
    AddOrUpdate,
    Upload,
    MaterialRecipe,
  },
  created() {
    this.getDataList();
  },
  methods: {
    //重新查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields();
      this.dataForm.bAve = "";
      this.dataForm.lAve = "";
      this.dataForm.aAve = "";
      this.restPage();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        productName: this.dataForm.productName,
        productNo: this.dataForm.productNo,
        lAve: this.dataForm.lAve,
        aAve: this.dataForm.aAve,
        bAve: this.dataForm.bAve,
      };
      list(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list;
          this.totalPage = data.page.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 配方上传文件
    uploadHandle() {
      this.uploadVisible = true;
      this.$nextTick(() => {
        this.$refs.upload.init();
      });
    },

    //配方详情
    addRecipeHandle(id) {
      this.materialRecipeVisible = true;
      this.$nextTick(() => {
        this.$refs.materialRecipe.init(id);
      });
    },
    //导出excel表格
    exportExcelHandle() {
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        productName: this.dataForm.productName,
        productNo: this.dataForm.productNo,
        lAve: this.dataForm.lAve,
        aAve: this.dataForm.aAve,
        bAve: this.dataForm.bAve,
      };
      axiosDownFile(
        "/api/recipe/recipe/exportExcel" +
          `?productName=${this.dataForm.productName}` +
          `&productNo=${this.dataForm.productNo}` +
          `&lAve=${this.dataForm.lAve}` +
          `&aAve=${this.dataForm.aAve}` +
          `&bAve=${this.dataForm.bAve}`
      );
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    //导出配方EXCEL表格
    excelTemplateHandle() {
      axiosDownFile("/api/recipe/recipe/recipeExcle");
    },
    // 新增 / 编辑
    addOrUpdateHandle(id, productId) {
      if (id) {
        var params = {
          recipeId: id,
        };
        isRecipeOrder(params).then(({ data }) => {
          if (data && data.code === 0) {
            // All is good
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
              this.$refs.addOrUpdate.init(id);
            });
          } else {
            this.$confirm(data.msg).then(() => {
              this.addRecipeHandle(productId);
            });
          }
        });
      } else {
        this.addOrUpdateVisible = true;
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id);
        });
      }
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`是否确认删除配方?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        del(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.w100 {
  width: 100px;
}
.mr10 {
  margin-right: 10px;
}
</style>
